import { graphql } from "gatsby";
import React from "react";
import Layout from "../../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { Link, Trans } from "gatsby-plugin-react-i18next";
import {
  ArrowRightIcon,
  CaretRightIcon,
} from "../../images/Icons";
import SwiperComponent from "../../components/swiper";
import HeroSm from "../../images/press/hero-sm.png"
import LogoUsageImage from "../../images/press/logo-usage.svg";
import TeamPicturesImage from "../../images/press/team-pictures.svg";
import ProductImagesImage from "../../images/press/product-images.svg";
import FeaturedBusinessesImage from "../../images/press/featured-businesses.svg";

const PressPage = () => {
  const MediaAssets = [
    { name: "presspage.logo_and_its_usage", link: "/press/logo-and-usage", image: LogoUsageImage },
    { name: "presspage.team_pictures", link: "/press/team-pictures", image: TeamPicturesImage },
    { name: "presspage.product_images", link: "/press/product-images", image: ProductImagesImage },
    { name: "presspage.featured_businesses", link: "/press/featured-businesses", image: FeaturedBusinessesImage }
  ]

  const News = [
    {
      image: "https://techpoint.africa/wp-content/uploads/2023/03/Image-1.jpeg",
      headline:
        "4 facts about Moniepoint’s newly appointed COO, Pawal Swiatek",
      link: "https://techpoint.africa/2023/03/06/moniepoint-new-coo-pawal-swiatek/"
    }, {
      image: "https://techpoint.africa/wp-content/uploads/2022/08/TeamApt-People-scaled.jpeg",
      headline:
        "Nigerian fintech, TeamApt, raises over $50 million to expand its credit offerings",
      link: "https://techpoint.africa/2022/08/11/teamapt-50m-expand-credit-offerings"
    },
    {
      image: "https://techcrunch.com/wp-content/uploads/2022/08/2A994DC0-838F-41DB-A6D4-5B145D8F46C8.jpeg?w=1390&crop=1",
      headline:
        "QED makes its first African investment, backing Nigerian fintech TeamApt in $50M+ deal",
      link: "https://techcrunch.com/2022/08/10/fintech-vc-qed-makes-its-first-african-investment-backing-nigerias-teamapt-in-50m-deal/?guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&guce_referrer_sig=AQAAAKjbfr5-9dEoWrXgYuLmCF0mX75El1VY6YwCTWnzFW9AsclUcCD6BO90q4xpmZR_kbD_0AJTY_wjl5n3eXwCWCgsaVMqMW71rhW9OIkepTm8Jj5GCSvP0HPQGX-7qc-d-t6uXIIruyxrUkjQaxskmN8NrmtqEa7aCI6t3z6Hs9E6&guccounter=2"
    },
    {
      image: "https://drive.google.com/uc?export=view&id=12M0gUHlewegra9bky70BKjGAeGspvNyb",
      headline:
        "Africa’s tech start-up scene is maturing,” says TeamApt CEO",
      link: "https://www.theafricareport.com/245399/africas-tech-start-up-scene-is-maturing-says-teamapt-ceo/"
    },
  ];


  return (
    <Layout pageTitle="Press - Moniepoint Inc.">
      <HeroContainer smSrc={HeroSm}>
        <div className="hero-sm-header"></div>
        <div className="hero-content-wrapper">
          <div className="hero-content">
            <h1 className="hero-title">
              <Trans>Press</Trans>
            </h1>
            <p className="hero-subtitle">
              <Trans>presspage.hero_subtitle_1</Trans><br /><br />
              <Trans>presspage.hero_subtitle_2</Trans><a href="mailto:press@moniepoint.com">press@moniepoint.com</a> <br className="hidden" /><Trans>presspage.hero_subtitle_3</Trans>
            </p>
          </div>
          <StaticImage
            alt="..."
            src="../../images/press/hero.png"
            className="hero-image grow"
          />
        </div>
      </HeroContainer>
      <MediaAssetsContainer>
        <div className="content">
          <h2 className="title">
            <Trans>presspage.media_assets</Trans>
          </h2>
          <div className="assets-section">
            {MediaAssets.map((asset, i) => (
              <AssetContainer key={i} image={asset.image}>
                <Link className="asset" to={asset.link}>
                  <img className="asset-image" src={asset.image} alt={asset.name} />
                  <p className="asset-title">
                    <Trans>{asset.name}</Trans>
                  </p>
                  <div className="asset-link">
                    <span><Trans>explore</Trans></span>
                    <CaretRightIcon color="#0361F0" size={19} />
                  </div>
                </Link>
              </AssetContainer>
            ))}
          </div>
        </div>
      </MediaAssetsContainer>
      <InTheNewsContainer>
        <div className="content">
          <div className="top-div">
            <h2 className="title">
              <Trans>presspage.in_the_news</Trans>
            </h2>
            <Link to="" className="button">
              <Trans>view_all</Trans>
              <ArrowRightIcon />
            </Link>
          </div>
          <div className="swiper-section">
            <SwiperComponent loop
              slidesPerView={'auto'}
              mousewheel={{ releaseOnEdges: true }}
              width={336}
              autoplay
              breakpoints={{
                768: {
                  slidesPerView: 1,
                  spaceBetween: 48,
                  width: 336,
                },
              }}>
              {
                News.map((news, i) => (
                  <div className="news-swipe-card grow">
                    <div className="card-img">
                      <img
                        alt="..."
                        src={news.image}
                        className=""
                      />
                    </div>
                    <div className="card-title">
                      {news.headline}
                    </div>
                    <a href={news.link} target="_blank" className="card-link">
                      <Trans>learn_more</Trans>
                      <CaretRightIcon size={18} />
                    </a>

                  </div>
                ))
              }
            </SwiperComponent>
          </div>
        </div>
      </InTheNewsContainer>
    </Layout>
  );
};

export default PressPage;

export const query = graphql`
   query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const HeroContainer = styled.section`
  background: white;

  .hero-sm-header{
    width: 100vw;
      height: 163px;
      background: url(${props => props.smSrc});
      background-size: cover;
      background-position: top right;
      display: none;
  }

  .hero-content-wrapper {
    height: 100%;
    max-width: 1440px;
    padding: 20px 150px 0px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 98px;

    @media only screen and (max-width: 1024px) {
      padding: 20px 60px 64px;
    }
  }

  .hero-content {
    width: 100%;
    .hero-title {
      font-style: normal;
      text-align: left;
      font-weight: 500;
      font-size: 59.8346px;
      line-height: 71px;
      margin-bottom: 22px;
      max-width: 800px;
      font-family: "Founders Grotesk";
    }

    .hero-subtitle {
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      margin-bottom: 0px;
      max-width: 586px;

      .hidden {
        display: none;
      }
      
      a {
        text-decoration: none;
        font-weight: 500;
      }
    }
  }

  .hero-image {
    height: 359px;
    max-width: 506px;
    border-radius: 8px;
    width: 100%;
  }

  @media only screen and (max-width: 768px) {

    .hero-sm-header{
      display: block;
    }
    .hero-content-wrapper {
      padding: 20px 20px 24px;
      flex-direction: column;
    }

    .hero-content {
      .hero-title {
        font-size: 40px;
        line-height: 44px;
        margin-bottom: 8px;
      }
      .hero-subtitle {
        font-size: 14px;
        font-weight: 400;
        line-height: 22.54px;
        letter-spacing: -0.003em;
        text-align: left;
        .hidden {
        display: block;
      }
      }
    }

    .hero-image {
      display: none;
    }
  }
`;

const InTheNewsContainer = styled.section`
  background-color: white;

  .content {
    max-width: 1440px;
    margin: auto;
    padding: 32px 150px;
    @media only screen and (max-width: 1024px) {
      padding: 32px 60px;
    }

    .top-div {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        font-family: "Founders Grotesk";
        font-size: 48px;
        font-weight: 500;
        line-height: 57px;
        max-width: 851px;
      }

      .button {
        white-space: nowrap;
        color: rgba(3, 97, 240, 1);
        padding: 16px 0px;
        border-radius: 4px;
        gap: 4px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: fit-content;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 130%;
        text-align: center;
        text-decoration: none;

        svg {
          path {
            fill: rgba(3, 97, 240, 1);
          }
          transition: all 0.2s ease-in-out;
        }

        :hover {
          svg{
            transform: translateX(4px);
          }
        }
      }
    }

    .swiper-section {
      margin-top: 32px;
      .swiper-slide {
    height: auto;
    max-height: 336px;
}
    }

    .news-swipe-card {
      width: 336px;
      height: 100%;
      display: flex;
    flex-direction: column;
    align-items: flex-start;
}

      .card-img {
        width: 336px;
        height: 187px;
        border-radius: 8px;
        margin-bottom: 24px;  
        overflow: hidden;

        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .card-title {
        font-family: "Founders Grotesk";
        font-size: 24px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 16px;
      }

      .card-link {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: center;
        text-decoration: none;
        color: rgba(3, 97, 240, 1);
        margin-top: auto;
       
        svg{
          transition: all 0.3s ease-in-out;
        }

        :hover{
          svg {
            transform: translateX(4px);
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .content {
      padding: 40px 20px;
      .top-div {
        .title {
          font-size: 35px;
          line-height: 41px;
        }
      }
      .swiper-section {
        margin-top: 32px;
      }
      .news-swipe-card {
        width: 330px;

        .card-img {
          width: 330px;
          height: 187px;
        }
      }
    }
  }
`;

const MediaAssetsContainer = styled.section`
  background-color: white;

  .content {
    max-width: 1440px;
    margin: auto;
    padding: 32px 150px;
    @media only screen and (max-width: 1024px) {
      padding: 32px 60px;
    }
    .title {
      font-family: "Founders Grotesk";
      font-size: 48px;
      font-weight: 500;
      line-height: 57px;
      max-width: 851px;
      margin-bottom: 48px;
   
    }

    .assets-section {
      display: grid;
      gap: 1rem;
      grid-template-columns: repeat(2, 1fr);

      @media screen and (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(4, 1fr);
      }

    }
  }

  @media screen and (max-width: 768px) {
    .content {
      padding: 24px 20px;

      .title {
        font-size: 24px;
        line-height: 38px;
        margin-bottom: 24px;
      }

    }
  }
`;

const AssetContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  height: 304px;
  width: 100%;
  max-width: 267.5px;
  border-radius: 8px;
  background: #0357EE08; 
  border: 1px solid #0357EE1A;
  border-radius: 8px;
  padding: 19px 16px;

  .asset {
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;

  .asset-image {
    margin: auto auto 0;
  }


  .asset-title {
    font-family: "Founders Grotesk";
    font-size: 22px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
    width: 100%;
    margin: 29px 0 33.5px;
    text-transform: capitalize;
  }

  .asset-link {
font-size: 14px;
font-weight: 500;
line-height: 18px;
letter-spacing: 0em;
text-align: center;
color: #0361F0;
text-decoration: none;
width: 100%;

  }

}
  @media screen and (max-width: 768px) {
    min-height: 195px;
    max-height: 245px;

    .asset{

      .asset-image {
   height: 112px;
   padding: 12px;
   margin: auto auto 0;
  }
    .asset-title {
font-size: 16px;
font-weight: 500;
line-height: 19px;
margin: 16px 0 16.5px;

    }
  }
}

`;